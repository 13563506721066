﻿.home-container {
    background: url(https://res.cloudinary.com/dealpos-m/image/upload/v1711960715/umb-website/Home/background_home_2024_meawna.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 576px) {
        background: url(https://res.cloudinary.com/dealpos-m/image/upload/w_800/v1711962172/umb-website/Home/home-mobile_new_n4yrfa.jpg);
        background-size: 100% auto;
        background-position: bottom center;
    }
}

main {
    p.text-secondary {
        line-height: 1.5rem;
    }

    div.jumbotron {
        padding-bottom: 160px;
    }
}

div.business-types {
    background: repeating-linear-gradient(-60deg, #f1f4f6, #dddfe11a 5px, #f2f2f2 3px, #e9ecef 3px) !important;
}

div.clients {
    img {
        height: 100px;
        width: 100px;
        background: #fff;
        box-shadow: 0 0 0 6px white, 2px 2px 13px black;
    }
}

.form-button {
    width: 225px;
}

@media screen and (max-width:576px) {
    body {
        background-position: -1015px 60px;
    }

    div.business-types {
        background-image: none;
    }

    .title {
        font-size: 31px;
    }

    .sub-title {
        font-size: 16px;
    }

    main {
        div.jumbotron {
            padding-bottom: calc(100px + 60vw);
            background-repeat: no-repeat;
        }
    }
}